import { Component, Vue } from 'vue-property-decorator'

type NotificationKind = 'error' | 'info' | 'success' | 'warning'

@Component
export default class NotificationMixin extends Vue {
  notification = {
    kind: '',
    title: '',
    message: '',
    open: false
  }

  setNotification (kind: NotificationKind, message: string) {
    const titleDict = { success: 'Sucesso', error: 'Erro', info: 'Informação', warning: 'Atenção' }

    let title = ''
    title = titleDict[kind]

    this.notification = {
      kind,
      message,
      open: true,
      title
    }
  }
}
